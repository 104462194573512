var spW = 768, // SP max width, not contain
  isSp, // Is SP or not
  winW, // Window width
  winH, // Window height
  winST, // Window scrollTop
  video, // video object
  video1, // video1 object
  video2, // video1 object
  isChange // Is change to PC/SP

$(function() {
  initCommon()
  initDevice()
  // initLink()
  initMenu()
  initScroll()

  $(window).load(function() {})

  $(window).scroll(function() {
    initScroll()
  })

  $(window).resize(function() {
    if (isChange) {
      initMenu()

      setTimeout(function() {
        $('img').each(function() {
          if (!$(this).data('original')) return
          $(this).attr('src', $(this).data('original'))
        })
      })
    }
  })
})

function initCommon() {
  $(window).load(function() {
    $('body').addClass('loaded')
    $('#gFooter .fNavi li a').matchHeight()

    setTimeout(function() {
      $('body').addClass('hideCover')
    })

    setTimeout(
      function() {
        initLazyload()
        setFadeInAnim()
      },
      isSp ? 0 : 800
    )
  })

  $(window)
    .scroll(function() {
      winST = $(window).scrollTop()
      setFadeInAnim()
    })
    .trigger('scroll')

  $(window)
    .resize(function() {
      getWinSize()
      setFadeInAnim()
    })
    .trigger('resize')
}

function getWinSize() {
  winW = $('html')
    .css('overflow', 'hidden')
    .width()
  winH = $(window).height()
  isChange = isSp !== undefined && isSp != winW < spW
  isSp = winW < spW
  $('html').removeAttr('style')
}

function initLazyload() {
  if (!$('img.lazy').length) return
  $('img.lazy').lazyload({
    threshold: 1,
    effect: 'fadeIn',
    effect_speed: 500,
    failure_limit: 1,
    placeholder:
      'data:image/gif;base64,R0lGODlhAQABAJEAAAAAMwAAAP///wAAACH5BAEAAAIALAAAAAABAAEAAAICVAEAOw=='
  })
}

function setFadeInAnim() {
  var fadeObj = $('.fadeInAnim')
  if (!fadeObj.length) return
  var delayH = 80

  fadeObj.each(function() {
    var elm = $(this),
      elmT = elm.offset().top,
      elmH = elm.height()

    if (winST > elmT - winH + delayH && winST < elmT + elmH) {
      elm.addClass('visible')
    } else if (winST < elmT - winH + delayH && winST < elmT + delayH) {
      elm.removeClass('visible')
    }
  })
}

function initDevice() {
  var userAgent = navigator.userAgent.toLowerCase()
  var pattern_phone = new RegExp('iphone', 'i')
  var pattern_android = new RegExp('Android', 'i')
  var isAndroid = pattern_android.test(userAgent)
  var isIphone = pattern_phone.test(userAgent)
  if (isAndroid) {
    $('body').addClass('Android')
    var version = (userAgent.match(/android [\d._]+/gi) + '')
      .replace(/[^0-9|_.]/gi, '')
      .replace(/_/gi, '.')
    version = parseInt(version.split('.')[0])
    if (version < 7) $('body').addClass('lowVersion')
  } else if (isIphone) {
    var width =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth
    if (width == 320) {
      $('body').addClass('iphone5')
    } else if (width == 375) {
      $('body').addClass('iphone678')
    } else if (width == 414) {
      $('body').addClass('iphone678Plus')
    }
  }

  if (
    userAgent.indexOf('windows nt 6.2') > -1 ||
    userAgent.indexOf('windows nt 6.3') > -1 ||
    userAgent.indexOf('windows 8') > -1
  ) {
    if (userAgent.indexOf('trident') > -1 && userAgent.indexOf('rv:11.0') > -1)
      $('body').addClass('win8IE11')
  }
}

function initMenu() {
  if (isSp || isiPad) {
    $('#gHeader .menu').unbind('mouseenter')
    $('#gHeader .menuBox').unbind('mouseleave')
    $('#gHeader .menu')
      .unbind('click')
      .on('click', function() {
        if ($('body').hasClass('menuOpen')) {
          $('body').removeClass('menuOpen')
        } else {
          $('body').addClass('menuOpen')
          $(
            '.menuBox .subMenu .menuUl01 > li li, .menuBox .subMenu .menuUl02'
          ).each(function(index, el) {
            $(this).on('click', function() {
              if (!IsPC()) {
                $('body').removeClass('menuOpen')
              }
            })
            TweenMax.set($(this), { y: 10, opacity: 0 })
            TweenMax.to($(this), 0.3, {
              delay:
                ($(this).offset().top - $('#gHeader .menuBox').offset().top) *
                0.0013,
              opacity: 1
            })
          })
        }
      })
  } else {
    $('#gHeader .menu').css({ top: $(window).height() / 2 })
    var allowOpen = true

    $('#gHeader .menu').on('mouseenter', function() {
      $('body').addClass('menuOpen')
      $('.menuBox')
        .unbind('mouseleave')
        .on('mouseleave', function(e) {
          if (e.relatedTarget !== null) $('body').removeClass('menuOpen')
        })
    })

    $('#gHeader .menu')
      .unbind('click')
      .on('click', function() {
        allowOpen = false
        $('body').removeClass('menuOpen')
        $('.menuBox').unbind('mouseleave')
        setTimeout(function() {
          allowOpen = true
        }, 500)
      })
  }

  $('.menuBox').css('transition', 'none')
  setTimeout(function() {
    $('.menuBox').css('transition', '')
  })
}

function initScroll() {
  if (winST == 0) {
    $('body').removeClass('scrollHeader')
  } else {
    $('body').addClass('scrollHeader')
  }
}

function initStepFade(elm, target) {
  if (!elm.length) return
  $(window)
    .scroll(function() {
      if (!elm.data('visible') && winST > elm.offset().top - winH * 0.8) {
        elm.data('visible', true)
        target = target !== undefined ? target : elm.children()
        target.each(function(i) {
          var _this = $(this)
          setTimeout(function() {
            _this.addClass('show')
          }, 120 * i)
        })
      }
    })
    .trigger('scroll')
}

function getQueryVariable(variable) {
  var query = location.search.substring(1)
  var vars = query.split('&')
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=')
    if (pair[0] == variable) {
      return pair[1]
    }
  }
  return false
}
